import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Button,
  Paper,
  CircularProgress
} from '@mui/material';
import { lessonService } from '../services/lesson.service';

interface TestComponentProps {
  testId: number;
  onSubmit: (answers: { [key: string]: string[] }) => void;
}

const TestComponent: React.FC<TestComponentProps> = ({ testId, onSubmit }) => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<any[]>([]);
  const [answers, setAnswers] = useState<{ [key: string]: string[] }>({});

  useEffect(() => {
    loadTest();
  }, [testId]);

  const loadTest = async () => {
    try {
      const testData = await lessonService.getTest(testId);
      setQuestions(testData.questions);
      // Инициализируем ответы
      const initialAnswers: { [key: string]: string[] } = {};
      testData.questions.forEach((q: any) => {
        initialAnswers[q.id] = [];
      });
      setAnswers(initialAnswers);
    } catch (err) {
      console.error('Failed to load test:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleAnswerChange = (questionId: string, answerId: string, isMultiple: boolean) => {
    setAnswers(prev => {
      if (isMultiple) {
        const current = prev[questionId] || [];
        return {
          ...prev,
          [questionId]: current.includes(answerId)
            ? current.filter(id => id !== answerId)
            : [...current, answerId]
        };
      }
      return {
        ...prev,
        [questionId]: [answerId]
      };
    });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      {questions.map((question, index) => (
        <Paper key={question.id} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            {index + 1}. {question.text}
          </Typography>
          <FormControl component="fieldset">
            {question.type === 'single' ? (
              <RadioGroup
                value={answers[question.id]?.[0] || ''}
                onChange={(e) => handleAnswerChange(question.id, e.target.value, false)}
              >
                {question.answers.map((answer: any) => (
                  <FormControlLabel
                    key={answer.id}
                    value={answer.id}
                    control={<Radio />}
                    label={answer.text}
                  />
                ))}
              </RadioGroup>
            ) : (
              <Box>
                {question.answers.map((answer: any) => (
                  <FormControlLabel
                    key={answer.id}
                    control={
                      <Checkbox
                        checked={answers[question.id]?.includes(answer.id)}
                        onChange={(e) => handleAnswerChange(question.id, answer.id, true)}
                      />
                    }
                    label={answer.text}
                  />
                ))}
              </Box>
            )}
          </FormControl>
        </Paper>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button
          variant="contained"
          onClick={() => onSubmit(answers)}
        >
          Submit Test
        </Button>
      </Box>
    </Box>
  );
};

export default TestComponent; 