import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import LanguageSwitcher from './components/LanguageSwitcher';

// Layouts
import MainLayout from './layouts/MainLayout';
import AuthLayout from './layouts/AuthLayout';

// Pages
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPassword from './pages/auth/ForgotPassword';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import LearningDashboard from './pages/LearningDashboard';
import LessonLibrary from './pages/LessonLibrary';
import LessonView from './pages/LessonView';

// Routes
import AdminRoutes from './routes/AdminRoutes';
import ContentRoutes from './routes/ContentRoutes';

const AppRoutes: React.FC = () => {
  const { user } = useAuth();

  return (
    <Routes>
      {/* Public routes - достъпни само за нелогнати потребители */}
      <Route element={<PublicRoute><AuthLayout /></PublicRoute>}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Route>

      {/* Protected routes - достъпни само за логнати потребители */}
      <Route element={
        <ProtectedRoute>
          <MainLayout />
        </ProtectedRoute>
      }>
        <Route path="/dashboard" element={
          user?.role === 'administrator' || user?.role === 'manager' ? 
            <Dashboard /> : 
            <LearningDashboard />
        } />
        
        {/* Admin only routes */}
        <Route path="/admin/*" element={
          <ProtectedRoute allowedRoles={['administrator']}>
            <AdminRoutes />
          </ProtectedRoute>
        } />
        
        {/* Content creator routes */}
        <Route path="/content/*" element={
          <ProtectedRoute allowedRoles={['administrator', 'manager', 'edu_user']}>
            <ContentRoutes />
          </ProtectedRoute>
        } />
        
        {/* Library routes - достъпни за всички потребители */}
        <Route path="/lessons" element={<LessonLibrary />} />
        <Route path="/lesson/:id" element={<LessonView />} />
      </Route>

      {/* Root redirect */}
      <Route path="/" element={
        <ProtectedRoute>
          <Navigate to="/dashboard" replace />
        </ProtectedRoute>
      } />

      {/* 404 */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App; 