interface CategoryFlat {
    id: number;
    name: string;
    description: string;
    parent_id: number | null;
    level: number;
}

interface CategoryTree {
    id: number;
    name: string;
    description: string;
    parent_id: number | null;
    level: number;
    children: CategoryTree[];
}

export const buildCategoryTree = (categories: CategoryFlat[]): CategoryTree[] => {
    const categoryMap = new Map<number, CategoryTree>();
    const roots: CategoryTree[] = [];

    // Първо създаваме всички категории с празни children масиви
    categories.forEach(cat => {
        categoryMap.set(cat.id, {
            ...cat,
            children: []
        });
    });

    // След това изграждаме дървовидната структура
    categories.forEach(cat => {
        const category = categoryMap.get(cat.id)!;
        
        if (cat.parent_id === null) {
            roots.push(category);
        } else {
            const parent = categoryMap.get(cat.parent_id);
            if (parent) {
                parent.children.push(category);
            }
        }
    });

    return roots;
}; 