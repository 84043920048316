import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Divider,
  Chip,
  CircularProgress,
  Alert
} from '@mui/material';
import {
  Star as StarIcon,
  PlayArrow as PlayArrowIcon
} from '@mui/icons-material';
import { lessonService } from '../services/lesson.service';
import TestComponent from '../components/TestComponent';
import { TestAnswers } from '../types/test.types';
import { useTranslation } from 'react-i18next';

interface LessonStep {
  id: number;
  title: string;
  content: string;
  estimated_time?: number;
}

interface LessonProcedure {
  id: number;
  title: string;
  description: string;
  conditions: string;
}

interface Lesson {
  id: number;
  title: string;
  description: string;
  content: string;
  category_name: string;
  difficulty_level: string;
  author_name: string;
  steps: LessonStep[];
  procedures: LessonProcedure[];
  estimated_time?: number;
  has_test: boolean;
  test_experience_points?: number;
  test_id?: number;
  test_passing_score?: number;
}

const LessonView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [lesson, setLesson] = useState<Lesson | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showTest, setShowTest] = useState(false);
  const [testSubmitted, setTestSubmitted] = useState(false);
  const [testResult, setTestResult] = useState<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    loadLesson();
  }, [id]);

  const loadLesson = async () => {
    try {
      const data = await lessonService.getById(Number(id));
      setLesson(data);
    } catch (err) {
      setError('Failed to load lesson');
    } finally {
      setLoading(false);
    }
  };

  const handleTestSubmit = async (answers: TestAnswers) => {
    if (!lesson || !lesson.test_id) return;

    try {
      const result = await lessonService.submitTest(lesson.test_id, answers);
      setTestResult(result);
      setTestSubmitted(true);
      setShowTest(false);
    } catch (err) {
      console.error('Failed to submit test:', err);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
        <Typography>{t('lesson.loading')}</Typography>
      </Box>
    );
  }

  if (!lesson) {
    return (
      <Alert severity="error">
        {t('lesson.notFound')}
      </Alert>
    );
  }

  return (
    <Box>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          {lesson.title}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
          <Chip label={t(`lesson.metadata.category`)} color="primary" />
          <Chip 
            label={t(`lesson.metadata.difficulty.${lesson.difficulty_level}`)}
            color={
              lesson.difficulty_level === 'beginner' ? 'success' :
              lesson.difficulty_level === 'intermediate' ? 'warning' : 'error'
            }
          />
          {lesson.estimated_time && (
            <Chip 
              label={t('lesson.metadata.estimatedTime', { time: lesson.estimated_time })}
              variant="outlined" 
            />
          )}
        </Box>
        <Typography variant="body1" paragraph>
          {lesson.description}
        </Typography>
        <Typography variant="caption">
          {t('lesson.metadata.author', { name: lesson.author_name })}
        </Typography>
      </Paper>

      {/* Основно съдържание */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          {t('lesson.overview')}
        </Typography>
        <Box 
          dangerouslySetInnerHTML={{ __html: lesson.content }}
          sx={{
            '& table': {
              borderCollapse: 'collapse',
              width: '100%',
              mb: 2,
            },
            '& td, & th': {
              border: '1px solid #ddd',
              padding: '8px',
            },
            '& tr:nth-of-type(even)': {
              backgroundColor: 'rgba(0, 0, 0, 0.02)',
            },
            '& img': {
              maxWidth: '100%',
              height: 'auto',
            },
            '& .emoji': {
              width: '1em',
              height: '1em',
              verticalAlign: 'middle',
            }
          }}
        />
      </Paper>

      {/* Стъпки */}
      {lesson.steps.length > 0 && (
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5" gutterBottom>
            {t('lesson.steps.title')}
          </Typography>
          <Stepper activeStep={activeStep} orientation="vertical">
            {lesson.steps.map((step, index) => (
              <Step key={step.id}>
                <StepLabel>
                  <Typography variant="subtitle1">
                    {step.title}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Box 
                    dangerouslySetInnerHTML={{ __html: step.content }}
                    sx={{
                      '& table': {
                        borderCollapse: 'collapse',
                        width: '100%',
                        mb: 2,
                      },
                      '& td, & th': {
                        border: '1px solid #ddd',
                        padding: '8px',
                      },
                      '& tr:nth-of-type(even)': {
                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                      },
                      '& img': {
                        maxWidth: '100%',
                        height: 'auto',
                      },
                      '& .emoji': {
                        width: '1em',
                        height: '1em',
                        verticalAlign: 'middle',
                      }
                    }}
                  />
                  {step.estimated_time && (
                    <Typography variant="caption" display="block" sx={{ mb: 2 }}>
                      {t('lesson.steps.estimatedTime', { time: step.estimated_time })}
                    </Typography>
                  )}
                  <Box sx={{ mb: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => setActiveStep(index + 1)}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === lesson.steps.length - 1 ? 
                        t('lesson.steps.finish') : 
                        t('lesson.steps.continue')}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={() => setActiveStep(index - 1)}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {t('lesson.steps.back')}
                    </Button>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Paper>
      )}

      {/* Процедури */}
      {lesson.procedures.length > 0 && (
        <Paper sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            {t('lesson.procedures.title')}
          </Typography>
          {lesson.procedures.map((procedure) => (
            <Box key={procedure.id} sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                {procedure.title}
              </Typography>
              <Typography variant="body1" paragraph>
                {procedure.description}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {t('lesson.procedures.whenToUse')}
              </Typography>
              <Typography variant="body2">
                {procedure.conditions}
              </Typography>
              <Divider sx={{ my: 2 }} />
            </Box>
          ))}
        </Paper>
      )}

      {/* Тест секция */}
      {lesson.has_test && lesson.test_id && (
        <Paper sx={{ p: 3, mt: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h5">
              {t('lesson.test.title')}
            </Typography>
            {lesson.test_experience_points && (
              <Chip
                icon={<StarIcon />}
                label={t('lesson.test.earnPoints', { points: lesson.test_experience_points })}
                color="secondary"
              />
            )}
          </Box>

          {!showTest && !testSubmitted && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body1" paragraph>
                {t('lesson.test.description')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PlayArrowIcon />}
                onClick={() => setShowTest(true)}
              >
                {t('lesson.test.start')}
              </Button>
            </Box>
          )}

          {showTest && !testSubmitted && (
            <TestComponent
              testId={lesson.test_id}
              onSubmit={handleTestSubmit}
            />
          )}

          {testSubmitted && testResult && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                {t('lesson.test.results.title')}
              </Typography>
              <Typography variant="h4" color={testResult.passed ? 'success.main' : 'error.main'}>
                {testResult.score}%
              </Typography>
              {testResult.passed ? (
                <>
                  <Typography variant="body1" color="success.main" paragraph>
                    {t('lesson.test.results.congratulations')}
                  </Typography>
                  <Typography variant="body2">
                    {t('lesson.test.results.experienceGained', { points: testResult.experience_gained })}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="body1" color="error.main" paragraph>
                    {t('lesson.test.results.failed', { required: lesson.test_passing_score || 70 })}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setShowTest(true);
                      setTestSubmitted(false);
                      setTestResult(null);
                    }}
                  >
                    {t('lesson.test.results.tryAgain')}
                  </Button>
                </>
              )}
              {!testResult.passed && testResult.next_attempt_at && (
                <Typography variant="body2" color="error.main">
                  {t('lesson.test.results.nextAttempt', { date: new Date(testResult.next_attempt_at).toLocaleString() })}
                </Typography>
              )}
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
};

export default LessonView; 