import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

const Logo: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%'
      }}
    >
      <Typography
        component="div"
        sx={{
          fontWeight: 900,
          background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          display: 'inline-block',
          fontSize: {
            xs: '1.2rem',
            sm: '1.4rem',
            md: '1.6rem'
          }
        }}
      >
        educator
      </Typography>
      <Typography
        sx={{
          fontWeight: 300,
          color: '#666',
          display: 'inline-block',
          fontSize: {
            xs: '1.2rem',
            sm: '1.4rem',
            md: '1.6rem'
          }
        }}
      >
        .wiki
      </Typography>
    </Box>
  );
};

export default Logo; 