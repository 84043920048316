import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  School as SchoolIcon,
  Category as CategoryIcon,
  Person as PersonIcon,
  ExitToApp as LogoutIcon,
  LibraryBooks as LibraryIcon,
  Star as StarIcon,
} from '@mui/icons-material';
import LanguageSwitcher from '../components/LanguageSwitcher';
import Logo from '../components/Logo';

const drawerWidth = 240;

const MainLayout: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const getMenuItems = () => {
    const items = [
      { text: t('nav.dashboard'), icon: <DashboardIcon />, path: '/dashboard' },
    ];

    items.push({ text: t('nav.library'), icon: <LibraryIcon />, path: '/lessons' });

    if (['administrator', 'manager', 'edu_user'].includes(user?.role || '')) {
      items.push({ text: t('nav.content'), icon: <SchoolIcon />, path: '/content' });
    }

    if (['administrator', 'manager'].includes(user?.role || '')) {
      items.push({ text: t('nav.manageCategories'), icon: <CategoryIcon />, path: '/admin/categories' });
      items.push({ text: t('nav.manageLevels'), icon: <StarIcon />, path: '/admin/levels' });
    }

    if (user?.role === 'administrator') {
      items.push({ text: t('nav.manageUsers'), icon: <PersonIcon />, path: '/admin/users' });
    }

    return items;
  };

  const drawer = (
    <div>
      <Box 
        sx={{ 
          p: 2,
          width: `${drawerWidth}px`,
          boxSizing: 'border-box',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            transform: 'scale(0.7)',
            transformOrigin: 'left center',
            width: '100%'
          }}
        >
          <Logo />
        </Box>
      </Box>
      <Divider />
      <List>
        {getMenuItems().map((item) => (
          <ListItem 
            button 
            key={item.text}
            onClick={() => navigate(item.path)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        <Divider />
        <ListItem button onClick={handleLogout}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary={t('nav.logout')} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <LanguageSwitcher />
          <Typography variant="subtitle2" sx={{ ml: 2 }}>
            {user?.first_name ? `${user.first_name} ${user.last_name || ''}` : user?.username} ({t(`roles.${user?.role}`)})
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mt: 8,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout; 