import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserManagement from '../pages/admin/UserManagement';
import CategoryManagement from '../pages/admin/CategoryManagement';
import LessonManagement from '../pages/admin/LessonManagement';
import LevelManagement from '../pages/admin/LevelManagement';

const AdminRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="users" element={<UserManagement />} />
      <Route path="categories" element={<CategoryManagement />} />
      <Route path="lessons" element={<LessonManagement />} />
      <Route path="levels" element={<LevelManagement />} />
    </Routes>
  );
};

export default AdminRoutes;
export {}; 