import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  LinearProgress,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Chip,
  CircularProgress,
  Alert,
  useTheme
} from '@mui/material';
import {
  School as SchoolIcon,
  Timer as TimerIcon,
  EmojiEvents as TrophyIcon,
  LocalFireDepartment as StreakIcon,
  Star as StarIcon,
  TrendingUp as TrendingUpIcon,
  Assignment as TestIcon
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { lessonService } from '../services/lesson.service';
import { useTranslation } from 'react-i18next';

const LearningDashboard: React.FC = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [stats, setStats] = useState<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    loadDashboardData();
  }, []);

  const loadDashboardData = async () => {
    try {
      const data = await lessonService.getDashboardStats();
      setStats(data);
    } catch (err) {
      setError('Failed to load dashboard data');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!stats) return null;

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t('dashboard.welcome', { name: user?.first_name || user?.username })}
      </Typography>

      {/* Основни статистики */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%', bgcolor: theme.palette.primary.light }}>
            <CardContent>
              <Box sx={{ color: 'white' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <SchoolIcon sx={{ mr: 1 }} />
                  <Typography variant="h6">
                    {t('dashboard.stats.completedLessons')}
                  </Typography>
                </Box>
                <Typography variant="h3">{stats.userStats.completed_lessons}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%', bgcolor: theme.palette.secondary.light }}>
            <CardContent>
              <Box sx={{ color: 'white' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <TimerIcon sx={{ mr: 1 }} />
                  <Typography variant="h6">{t('dashboard.stats.learningTime')}</Typography>
                </Box>
                <Typography variant="h3">
                  {Math.floor(stats.userStats.total_minutes / 60)}{t('dashboard.stats.hours')}
                </Typography>
                <Typography variant="subtitle2">
                  {stats.userStats.total_minutes % 60}{t('dashboard.stats.minutes')}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%', bgcolor: theme.palette.success.light }}>
            <CardContent>
              <Box sx={{ color: 'white' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <StreakIcon sx={{ mr: 1 }} />
                  <Typography variant="h6">{t('dashboard.stats.currentStreak')}</Typography>
                </Box>
                <Typography variant="h3">{stats.userStats.current_streak}</Typography>
                <Typography variant="subtitle2">{t('dashboard.stats.days')}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%', bgcolor: theme.palette.warning.light }}>
            <CardContent>
              <Box sx={{ color: 'white' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <StarIcon sx={{ mr: 1 }} />
                  <Typography variant="h6">{t('dashboard.stats.experience')}</Typography>
                </Box>
                <Typography variant="h3">{stats.userStats.experience_points}</Typography>
                <Typography variant="subtitle2">
                  {t('dashboard.stats.level')} {stats.userStats.level}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Прогрес към следващо ниво */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TrendingUpIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Level Progress</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Box sx={{ flexGrow: 1, mr: 1 }}>
            <LinearProgress
              variant="determinate"
              value={(stats.userStats.experience_points / stats.userStats.next_level_points) * 100}
              sx={{ height: 10, borderRadius: 5 }}
            />
          </Box>
          <Typography variant="body2" color="text.secondary">
            {stats.userStats.experience_points} / {stats.userStats.next_level_points} XP
          </Typography>
        </Box>
        <Typography variant="body2" color="text.secondary">
          {Math.ceil(stats.userStats.next_level_points - stats.userStats.experience_points)} XP needed for next level
        </Typography>
      </Paper>

      {/* Последни активности */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              {t('dashboard.recentProgress.title')}
            </Typography>
            <List>
              {stats.recentProgress.map((progress: any) => (
                <ListItem key={progress.lessonId}>
                  <ListItemIcon>
                    <SchoolIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={progress.title}
                    secondary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={progress.progress}
                          sx={{ flexGrow: 1 }}
                        />
                        <Typography variant="body2">
                          {progress.progress}%
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
              ))}
              {stats.recentProgress.length === 0 && (
                <ListItem>
                  <ListItemText
                    primary={t('dashboard.recentProgress.noProgress')}
                    secondary={t('dashboard.recentProgress.startLearning')}
                  />
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              {t('dashboard.recentTests.title')}
            </Typography>
            <List>
              {stats.recentTests.map((test: any) => (
                <ListItem key={test.testId}>
                  <ListItemIcon>
                    <TestIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={test.lessonTitle}
                    secondary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Chip
                          size="small"
                          label={`${test.score}%`}
                          color={test.score >= 70 ? 'success' : 'error'}
                        />
                        {test.experienceGained > 0 && (
                          <Chip
                            size="small"
                            icon={<StarIcon />}
                            label={`+${test.experienceGained} XP`}
                            color="warning"
                          />
                        )}
                      </Box>
                    }
                  />
                </ListItem>
              ))}
              {stats.recentTests.length === 0 && (
                <ListItem>
                  <ListItemText
                    primary={t('dashboard.recentTests.noTests')}
                    secondary={t('dashboard.recentTests.takeTest')}
                  />
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LearningDashboard; 