import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    Button, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,
    IconButton,
    Chip,
    CircularProgress,
    Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { 
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Visibility as ViewIcon
} from '@mui/icons-material';
import { lessonService } from '../../services/lesson.service';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

const LessonList: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [lessons, setLessons] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        loadLessons();
    }, []);

    const loadLessons = async () => {
        try {
            const data = await lessonService.getAll();
            setLessons(data);
        } catch (err) {
            setError('Failed to load lessons');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id: number) => {
        if (window.confirm('Are you sure you want to delete this lesson?')) {
            try {
                await lessonService.delete(id);
                await loadLessons();
            } catch (err) {
                setError('Failed to delete lesson');
            }
        }
    };

    const handlePublish = async (id: number) => {
        try {
            await lessonService.update(id, { is_published: true });
            await loadLessons();
        } catch (err) {
            setError('Failed to publish lesson');
        }
    };

    const canEdit = (authorId: number) => {
        return user?.role === 'administrator' || 
               user?.role === 'manager' || 
               user?.id === authorId;
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography variant="h4">{t('content.title')}</Typography>
                {['administrator', 'manager', 'edu_user'].includes(user?.role || '') && (
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => navigate('/content/create')}
                    >
                        {t('content.lessons.createNew')}
                    </Button>
                )}
            </Box>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('content.lessons.table.title')}</TableCell>
                            <TableCell>{t('content.lessons.table.category')}</TableCell>
                            <TableCell>{t('content.lessons.table.author')}</TableCell>
                            <TableCell>{t('content.lessons.table.status')}</TableCell>
                            <TableCell>{t('content.lessons.table.steps')}</TableCell>
                            <TableCell>{t('content.lessons.table.created')}</TableCell>
                            <TableCell>{t('content.lessons.table.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lessons.map((lesson) => (
                            <TableRow key={lesson.id}>
                                <TableCell>{lesson.title}</TableCell>
                                <TableCell>{lesson.category_name}</TableCell>
                                <TableCell>{lesson.author_name}</TableCell>
                                <TableCell>
                                    <Chip 
                                        label={t(lesson.is_published ? 
                                            'content.lessons.status.published' : 
                                            'content.lessons.status.draft')}
                                        color={lesson.is_published ? 'success' : 'default'}
                                        size="small"
                                    />
                                    {!lesson.is_published && canEdit(lesson.id) && (
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handlePublish(lesson.id)}
                                            sx={{ ml: 1 }}
                                        >
                                            {t('content.lessons.status.publish')}
                                        </Button>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {lesson.steps_count} {t('content.lessons.table.steps')}, 
                                    {lesson.procedures_count} {t('content.lessons.table.procedures')}
                                </TableCell>
                                <TableCell>
                                    {new Date(lesson.created_at).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        size="small"
                                        onClick={() => navigate(`/lesson/${lesson.id}`)}
                                    >
                                        <ViewIcon />
                                    </IconButton>
                                    {canEdit(lesson.id) && (
                                        <>
                                            <IconButton
                                                size="small"
                                                onClick={() => navigate(`/content/edit/${lesson.id}`)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                color="error"
                                                onClick={() => handleDelete(lesson.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default LessonList; 