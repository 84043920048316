import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  IconButton,
  Paper,
  Divider
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIcon
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Test, Question, Answer } from '../types/test.types';

interface TestEditorProps {
  value: Test;
  onChange: (test: Test) => void;
}

const TestEditor: React.FC<TestEditorProps> = ({ value, onChange }) => {
  const addQuestion = () => {
    const newQuestion: Question = {
      id: Date.now().toString(),
      text: '',
      type: 'single',
      points: 1,
      answers: []
    };
    onChange({
      ...value,
      questions: [...value.questions, newQuestion]
    });
  };

  const addAnswer = (questionId: string) => {
    const newAnswer: Answer = {
      id: Date.now().toString(),
      text: '',
      isCorrect: false
    };
    onChange({
      ...value,
      questions: value.questions.map(q => 
        q.id === questionId 
          ? { ...q, answers: [...q.answers, newAnswer] }
          : q
      )
    });
  };

  const updateQuestion = (questionId: string, updates: Partial<Question>) => {
    onChange({
      ...value,
      questions: value.questions.map(q =>
        q.id === questionId ? { ...q, ...updates } : q
      )
    });
  };

  const updateAnswer = (questionId: string, answerId: string, updates: Partial<Answer>) => {
    onChange({
      ...value,
      questions: value.questions.map(q =>
        q.id === questionId
          ? {
              ...q,
              answers: q.answers.map(a =>
                a.id === answerId ? { ...a, ...updates } : a
              )
            }
          : q
      )
    });
  };

  const removeQuestion = (questionId: string) => {
    onChange({
      ...value,
      questions: value.questions.filter(q => q.id !== questionId)
    });
  };

  const removeAnswer = (questionId: string, answerId: string) => {
    onChange({
      ...value,
      questions: value.questions.map(q =>
        q.id === questionId
          ? { ...q, answers: q.answers.filter(a => a.id !== answerId) }
          : q
      )
    });
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const questions = Array.from(value.questions);
    const [reorderedItem] = questions.splice(result.source.index, 1);
    questions.splice(result.destination.index, 0, reorderedItem);

    onChange({
      ...value,
      questions
    });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Test Configuration
      </Typography>
      
      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="Test Title"
          value={value.title}
          onChange={(e) => onChange({ ...value, title: e.target.value })}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Description"
          multiline
          rows={2}
          value={value.description}
          onChange={(e) => onChange({ ...value, description: e.target.value })}
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            type="number"
            label="Experience Points"
            value={value.experiencePoints}
            onChange={(e) => onChange({ 
              ...value, 
              experiencePoints: parseInt(e.target.value) || 0 
            })}
          />
          <TextField
            type="number"
            label="Passing Score (%)"
            value={value.passingScore}
            onChange={(e) => onChange({ 
              ...value, 
              passingScore: parseInt(e.target.value) || 0 
            })}
          />
        </Box>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Questions
        </Typography>
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={addQuestion}
        >
          Add Question
        </Button>
      </Box>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="questions">
          {(provided) => (
            <Box 
              {...provided.droppableProps} 
              ref={provided.innerRef}
            >
              {value.questions.map((question, index) => (
                <Draggable
                  key={question.id}
                  draggableId={question.id}
                  index={index}
                >
                  {(provided) => (
                    <Paper
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{ p: 2, mb: 2 }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <IconButton {...provided.dragHandleProps}>
                          <DragIcon />
                        </IconButton>
                        <Typography variant="subtitle1">
                          Question {index + 1}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton
                          color="error"
                          onClick={() => removeQuestion(question.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>

                      <TextField
                        fullWidth
                        label="Question Text"
                        value={question.text}
                        onChange={(e) => updateQuestion(question.id, { 
                          text: e.target.value 
                        })}
                        sx={{ mb: 2 }}
                      />

                      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <FormControl>
                          <RadioGroup
                            row
                            value={question.type}
                            onChange={(e) => updateQuestion(question.id, {
                              type: e.target.value as 'single' | 'multiple'
                            })}
                          >
                            <FormControlLabel
                              value="single"
                              control={<Radio />}
                              label="Single Choice"
                            />
                            <FormControlLabel
                              value="multiple"
                              control={<Radio />}
                              label="Multiple Choice"
                            />
                          </RadioGroup>
                        </FormControl>
                        <TextField
                          type="number"
                          label="Points"
                          value={question.points}
                          onChange={(e) => updateQuestion(question.id, {
                            points: parseInt(e.target.value) || 1
                          })}
                          sx={{ width: 100 }}
                        />
                      </Box>

                      <Box sx={{ ml: 3 }}>
                        {question.answers.map((answer, aIndex) => (
                          <Box
                            key={answer.id}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mb: 1
                            }}
                          >
                            {question.type === 'single' ? (
                              <Radio
                                checked={answer.isCorrect}
                                onChange={(e) => {
                                  const newAnswers = question.answers.map(a => ({
                                    ...a,
                                    isCorrect: a.id === answer.id
                                  }));
                                  updateQuestion(question.id, {
                                    answers: newAnswers
                                  });
                                }}
                              />
                            ) : (
                              <Checkbox
                                checked={answer.isCorrect}
                                onChange={(e) => updateAnswer(
                                  question.id,
                                  answer.id,
                                  { isCorrect: e.target.checked }
                                )}
                              />
                            )}
                            <TextField
                              fullWidth
                              size="small"
                              value={answer.text}
                              onChange={(e) => updateAnswer(
                                question.id,
                                answer.id,
                                { text: e.target.value }
                              )}
                            />
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => removeAnswer(question.id, answer.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                        <Button
                          startIcon={<AddIcon />}
                          onClick={() => addAnswer(question.id)}
                          size="small"
                        >
                          Add Answer
                        </Button>
                      </Box>
                    </Paper>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default TestEditor; 