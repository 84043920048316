import axios from 'axios';
import { API_BASE_URL, getAuthHeader } from '../config/api.config';
import { Test, TestAnswers } from '../types/test.types';
import { Lesson } from '../types/lesson.types';

const LESSONS_URL = `${API_BASE_URL}/lessons`;

export const lessonService = {
    async getAll() {
        const response = await axios.get(LESSONS_URL, getAuthHeader());
        return response.data;
    },

    async getById(id: number) {
        const response = await axios.get(`${LESSONS_URL}/${id}`, getAuthHeader());
        return response.data;
    },

    async create(lessonData: any) {
        const response = await axios.post(LESSONS_URL, lessonData, getAuthHeader());
        return response.data;
    },

    async update(id: number, updateData: any) {
        const response = await axios.put(`${LESSONS_URL}/${id}`, updateData, getAuthHeader());
        return response.data;
    },

    async delete(id: number) {
        const response = await axios.delete(`${LESSONS_URL}/${id}`, getAuthHeader());
        return response.data;
    },

    async getPublicLessons() {
        const response = await axios.get(`${LESSONS_URL}/public`);
        return response.data;
    },

    async getDashboardStats() {
        const response = await axios.get(`${LESSONS_URL}/dashboard-stats`, getAuthHeader());
        return response.data;
    },

    async getTest(testId: number): Promise<Test> {
        const response = await axios.get(`${LESSONS_URL}/tests/${testId}`, getAuthHeader());
        return response.data;
    },

    async submitTest(testId: number, answers: TestAnswers) {
        const response = await axios.post(
            `${LESSONS_URL}/tests/${testId}/submit`,
            { answers },
            getAuthHeader()
        );
        return response.data;
    }
}; 