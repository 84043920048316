import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LessonCreate from '../pages/content/LessonCreate';
import LessonEdit from '../pages/content/LessonEdit';
import LessonList from '../pages/content/LessonList';

const ContentRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<LessonList />} />
      <Route path="create" element={<LessonCreate />} />
      <Route path="edit/:id" element={<LessonEdit />} />
    </Routes>
  );
};

export default ContentRoutes; 