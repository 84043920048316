import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Paper,
  IconButton,
  Alert,
  CircularProgress,
  FormControlLabel,
  Switch,
  Container,
  Grid
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { categoryService } from '../../services/category.service';
import { lessonService } from '../../services/lesson.service';
import RichTextEditor from '../../components/RichTextEditor';
import TestEditor from '../../components/TestEditor';
import { Test } from '../../types/test.types';
import { useTranslation } from 'react-i18next';

interface Category {
  id: number;
  name: string;
  parent_id: number | null;
  level: number;
}

interface LessonStep {
  title: string;
  content: string;
  estimated_time?: number;
}

interface LessonProcedure {
  title: string;
  description: string;
  conditions: string;
}

const LessonCreate: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  
  const [lessonData, setLessonData] = useState({
    title: '',
    description: '',
    category_id: '',
    difficulty_level: 'beginner',
    estimated_time: '',
    content: ''
  });

  const [steps, setSteps] = useState<LessonStep[]>([]);
  const [procedures, setProcedures] = useState<LessonProcedure[]>([]);
  const [hasTest, setHasTest] = useState(false);
  const [testData, setTestData] = useState<Test>({
    title: '',
    description: '',
    experiencePoints: 100,
    passingScore: 70,
    questions: []
  });

  const { t } = useTranslation();

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    try {
      const data = await categoryService.getAll();
      setCategories(data);
    } catch (err) {
      setError('Failed to load categories');
    }
  };

  const handleAddStep = () => {
    setSteps([...steps, { title: '', content: '', estimated_time: undefined }]);
  };

  const handleRemoveStep = (index: number) => {
    setSteps(steps.filter((_, i) => i !== index));
  };

  const handleAddProcedure = () => {
    setProcedures([...procedures, { title: '', description: '', conditions: '' }]);
  };

  const handleRemoveProcedure = (index: number) => {
    setProcedures(procedures.filter((_, i) => i !== index));
  };

  const handleStepChange = useCallback((index: number, field: keyof LessonStep, value: string | number) => {
    setSteps(prevSteps => {
      const newSteps = [...prevSteps];
      newSteps[index] = { 
        ...newSteps[index], 
        [field]: field === 'estimated_time' ? Number(value) : value 
      };
      return newSteps;
    });
  }, []);

  const handleStepContentChange = useCallback((index: number, content: string) => {
    handleStepChange(index, 'content', content);
  }, [handleStepChange]);

  const handleProcedureChange = (index: number, field: keyof LessonProcedure, value: string) => {
    const newProcedures = [...procedures];
    newProcedures[index] = { ...newProcedures[index], [field]: value };
    setProcedures(newProcedures);
  };

  const validateForm = () => {
    if (!lessonData.title.trim()) {
      setError('Title is required');
      return false;
    }
    if (!lessonData.category_id) {
      setError('Category is required');
      return false;
    }
    if (steps.some(step => !step.title.trim() || !step.content.trim())) {
      setError('All steps must have a title and content');
      return false;
    }
    if (procedures.some(proc => !proc.title.trim() || !proc.description.trim() || !proc.conditions.trim())) {
      setError('All procedures must have a title, description and conditions');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const dataToSubmit = {
        ...lessonData,
        steps: steps.map((step, index) => ({
          ...step,
          order_number: index + 1
        })),
        procedures: procedures.map((proc, index) => ({
          ...proc,
          order_number: index + 1
        })),
        test: hasTest ? testData : null,
        created_by: user?.id,
        is_published: false
      };

      await lessonService.create(dataToSubmit);
      navigate('/content');
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to create lesson');
    } finally {
      setLoading(false);
    }
  };

  const handleTestChange = (newTest: Test) => {
    setTestData(newTest);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4">
          {t('content.lessonForm.createTitle')}
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            {t('content.lessonForm.basicInfo')}
          </Typography>
          
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('content.lessonForm.fields.title')}
                placeholder={t('content.lessonForm.fields.titlePlaceholder')}
                value={lessonData.title}
                onChange={(e) => setLessonData({ ...lessonData, title: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Category</InputLabel>
                <Select
                  value={lessonData.category_id}
                  label="Category"
                  onChange={(e) => setLessonData({ ...lessonData, category_id: e.target.value })}
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {'—'.repeat(category.level)} {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Difficulty Level</InputLabel>
                <Select
                  value={lessonData.difficulty_level}
                  label="Difficulty Level"
                  onChange={(e) => setLessonData({ ...lessonData, difficulty_level: e.target.value })}
                >
                  <MenuItem value="beginner">Beginner</MenuItem>
                  <MenuItem value="intermediate">Intermediate</MenuItem>
                  <MenuItem value="advanced">Advanced</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('content.lessonForm.fields.description')}
                placeholder={t('content.lessonForm.fields.descriptionPlaceholder')}
                multiline
                rows={3}
                value={lessonData.description}
                onChange={(e) => setLessonData({ ...lessonData, description: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('content.lessonForm.fields.estimatedTime')}
                type="number"
                value={lessonData.estimated_time}
                onChange={(e) => setLessonData({ ...lessonData, estimated_time: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                  {t('content.lessonForm.fields.content')}
                </Typography>
                <RichTextEditor
                  value={lessonData.content}
                  onChange={(content) => setLessonData({ ...lessonData, content })}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>

        {/* Стъпки */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            {t('content.lessonForm.steps.title')}
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddStep}
              variant="outlined"
              size="small"
              sx={{ ml: 2 }}
            >
              {t('content.lessonForm.steps.addStep')}
            </Button>
          </Typography>
          
          {steps.map((step, index) => (
            <Box key={index} sx={{ p: 2, border: '1px solid #ddd', borderRadius: 1, mb: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="subtitle1">Step {index + 1}</Typography>
                <IconButton onClick={() => handleRemoveStep(index)} color="error" size="small">
                  <DeleteIcon />
                </IconButton>
              </Box>
              
              <TextField
                label={t('content.lessonForm.steps.stepTitle')}
                value={step.title}
                onChange={(e) => handleStepChange(index, 'title', e.target.value)}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <RichTextEditor
                value={step.content}
                onChange={(content) => handleStepContentChange(index, content)}
                height={300}
              />
              <TextField
                label={t('content.lessonForm.steps.estimatedTime')}
                type="number"
                value={step.estimated_time || ''}
                onChange={(e) => handleStepChange(index, 'estimated_time', e.target.value)}
                fullWidth
              />
            </Box>
          ))}
        </Box>

        {/* Процедури */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            {t('content.lessonForm.procedures.title')}
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddProcedure}
              variant="outlined"
              size="small"
              sx={{ ml: 2 }}
            >
              {t('content.lessonForm.procedures.addProcedure')}
            </Button>
          </Typography>
          
          {procedures.map((procedure, index) => (
            <Box key={index} sx={{ p: 2, border: '1px solid #ddd', borderRadius: 1, mb: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="subtitle1">Procedure {index + 1}</Typography>
                <IconButton onClick={() => handleRemoveProcedure(index)} color="error" size="small">
                  <DeleteIcon />
                </IconButton>
              </Box>
              
              <TextField
                label={t('content.lessonForm.procedures.procedureTitle')}
                value={procedure.title}
                onChange={(e) => handleProcedureChange(index, 'title', e.target.value)}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <TextField
                label={t('content.lessonForm.procedures.description')}
                value={procedure.description}
                onChange={(e) => handleProcedureChange(index, 'description', e.target.value)}
                multiline
                rows={3}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <TextField
                label={t('content.lessonForm.procedures.conditions')}
                value={procedure.conditions}
                onChange={(e) => handleProcedureChange(index, 'conditions', e.target.value)}
                multiline
                rows={2}
                fullWidth
                required
              />
            </Box>
          ))}
        </Box>

        {/* Тестова секция */}
        <Box sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <Typography variant="h6">
              {t('content.lessonForm.test.sectionTitle')}
            </Typography>
            <FormControlLabel
              control={<Switch checked={hasTest} onChange={(e) => setHasTest(e.target.checked)} />}
              label={t('content.lessonForm.test.addTest')}
            />
          </Box>

          {hasTest && (
            <Paper sx={{ p: 2, mb: 2 }}>
              <TestEditor
                value={testData}
                onChange={handleTestChange}
              />
            </Paper>
          )}
        </Box>

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 3 }}>
          <Button variant="outlined" onClick={() => navigate('/content')}>
            {t('content.lessonForm.buttons.cancel')}
          </Button>
          <Button variant="contained" type="submit">
            {t('content.lessonForm.buttons.create')}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default LessonCreate; 