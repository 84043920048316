import axios from 'axios';
import { API_BASE_URL } from '../config/api.config';

const AUTH_URL = `${API_BASE_URL}/auth`;

export const authService = {
    async login(credentials: { email: string; password: string }) {
        const response = await axios.post(`${AUTH_URL}/login`, credentials);
        if (response.data.token) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data.user));
        }
        return response.data;
    },

    async register(userData: any) {
        const response = await axios.post(`${AUTH_URL}/register`, userData);
        return response.data;
    },

    getCurrentUser() {
        const userStr = localStorage.getItem('user');
        if (userStr) {
            return JSON.parse(userStr);
        }
        return null;
    },

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    }
}; 