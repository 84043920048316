import axios from 'axios';
import { API_BASE_URL, getAuthHeader } from '../config/api.config';

const CATEGORIES_URL = `${API_BASE_URL}/categories`;

export const categoryService = {
    async getAll() {
        const response = await axios.get(CATEGORIES_URL, getAuthHeader());
        return response.data;
    },

    async create(data: any) {
        const response = await axios.post(CATEGORIES_URL, data, getAuthHeader());
        return response.data;
    },

    async update(id: number, data: any) {
        const response = await axios.put(`${CATEGORIES_URL}/${id}`, data, getAuthHeader());
        return response.data;
    },

    async delete(id: number) {
        const response = await axios.delete(`${CATEGORIES_URL}/${id}`, getAuthHeader());
        return response.data;
    }
}; 