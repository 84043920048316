import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  IconButton,
  Alert,
  CircularProgress,
  FormControlLabel,
  Switch,
  Divider
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { lessonService } from '../../services/lesson.service';
import { categoryService } from '../../services/category.service';
import RichTextEditor from '../../components/RichTextEditor';
import TestEditor from '../../components/TestEditor';
import { Test } from '../../types/test.types';
import { useTranslation } from 'react-i18next';

const LessonEdit: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [categories, setCategories] = useState<any[]>([]);
  const [hasTest, setHasTest] = useState(false);
  
  const [lessonData, setLessonData] = useState({
    title: '',
    description: '',
    content: '',
    category_id: '',
    difficulty_level: 'beginner',
    estimated_time: '',
    is_published: false
  });

  const [steps, setSteps] = useState<any[]>([]);
  const [procedures, setProcedures] = useState<any[]>([]);
  const [testData, setTestData] = useState<Test>({
    title: '',
    description: '',
    experiencePoints: 100,
    passingScore: 70,
    questions: []
  });

  useEffect(() => {
    loadData();
  }, [id]);

  const loadData = async () => {
    try {
      setLoading(true);
      const [lessonResponse, categoriesResponse] = await Promise.all([
        lessonService.getById(Number(id)),
        categoryService.getAll()
      ]);

      setCategories(categoriesResponse);
      
      const lesson = lessonResponse;
      setLessonData({
        title: lesson.title,
        description: lesson.description,
        content: lesson.content,
        category_id: lesson.category_id.toString(),
        difficulty_level: lesson.difficulty_level,
        estimated_time: lesson.estimated_time?.toString() || '',
        is_published: lesson.is_published
      });

      setSteps(lesson.steps || []);
      setProcedures(lesson.procedures || []);
      
      if (lesson.has_test && lesson.test_id) {
        setHasTest(true);
        const testResponse = await lessonService.getTest(lesson.test_id);
        setTestData(testResponse);
      }

    } catch (err) {
      setError('Failed to load lesson data');
      console.error('Error loading lesson:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddStep = () => {
    setSteps([...steps, { title: '', content: '', estimated_time: undefined }]);
  };

  const handleRemoveStep = (index: number) => {
    setSteps(steps.filter((_, i) => i !== index));
  };

  const handleStepChange = (index: number, field: string, value: string) => {
    const newSteps = [...steps];
    newSteps[index] = { ...newSteps[index], [field]: value };
    setSteps(newSteps);
  };

  const handleAddProcedure = () => {
    setProcedures([...procedures, { title: '', description: '', conditions: '' }]);
  };

  const handleRemoveProcedure = (index: number) => {
    setProcedures(procedures.filter((_, i) => i !== index));
  };

  const handleProcedureChange = (index: number, field: string, value: string) => {
    const newProcedures = [...procedures];
    newProcedures[index] = { ...newProcedures[index], [field]: value };
    setProcedures(newProcedures);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = {
        ...lessonData,
        steps: steps.map((step, index) => ({
          ...step,
          order_number: index + 1
        })),
        procedures: procedures.map((proc, index) => ({
          ...proc,
          order_number: index + 1
        })),
        test: hasTest ? testData : null
      };

      await lessonService.update(Number(id), dataToSubmit);
      navigate('/content');
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to update lesson');
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t('content.lessonForm.editTitle')}
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      
      <Paper sx={{ p: 3 }}>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Основна информация */}
          <TextField
            label={t('content.lessonForm.fields.title')}
            value={lessonData.title}
            onChange={(e) => setLessonData({ ...lessonData, title: e.target.value })}
            required
            fullWidth
          />
          
          <FormControl fullWidth required>
            <InputLabel>{t('content.lessonForm.fields.category')}</InputLabel>
            <Select
              value={lessonData.category_id}
              label={t('content.lessonForm.fields.category')}
              onChange={(e) => setLessonData({ ...lessonData, category_id: e.target.value })}
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {'—'.repeat(category.level)} {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>{t('content.lessonForm.fields.difficulty.label')}</InputLabel>
            <Select
              value={lessonData.difficulty_level}
              label={t('content.lessonForm.fields.difficulty.label')}
              onChange={(e) => setLessonData({ ...lessonData, difficulty_level: e.target.value })}
            >
              <MenuItem value="beginner">{t('content.lessonForm.fields.difficulty.beginner')}</MenuItem>
              <MenuItem value="intermediate">{t('content.lessonForm.fields.difficulty.intermediate')}</MenuItem>
              <MenuItem value="advanced">{t('content.lessonForm.fields.difficulty.advanced')}</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label={t('content.lessonForm.fields.description')}
            value={lessonData.description}
            onChange={(e) => setLessonData({ ...lessonData, description: e.target.value })}
            multiline
            rows={3}
            fullWidth
          />

          <TextField
            label={t('content.lessonForm.fields.estimatedTime')}
            type="number"
            value={lessonData.estimated_time}
            onChange={(e) => setLessonData({ ...lessonData, estimated_time: e.target.value })}
            fullWidth
          />

          {/* Основно съдържание */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              {t('content.lessonForm.fields.content')}
            </Typography>
            <RichTextEditor
              value={lessonData.content}
              onChange={(content) => setLessonData({ ...lessonData, content })}
            />
          </Box>

          {/* Стъпки */}
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              {t('content.lessonForm.steps.title')}
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddStep}
                variant="outlined"
                size="small"
                sx={{ ml: 2 }}
              >
                {t('content.lessonForm.steps.addStep')}
              </Button>
            </Typography>
            
            {steps.map((step, index) => (
              <Box key={index} sx={{ p: 2, border: '1px solid #ddd', borderRadius: 1, mb: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="subtitle1">{t('content.lessonForm.steps.step')} {index + 1}</Typography>
                  <IconButton onClick={() => handleRemoveStep(index)} color="error" size="small">
                    <DeleteIcon />
                  </IconButton>
                </Box>
                
                <TextField
                  label={t('content.lessonForm.steps.stepTitle')}
                  value={step.title}
                  onChange={(e) => handleStepChange(index, 'title', e.target.value)}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                />
                <RichTextEditor
                  value={step.content}
                  onChange={(content) => handleStepChange(index, 'content', content)}
                  height={300}
                />
                <TextField
                  label={t('content.lessonForm.steps.estimatedTime')}
                  type="number"
                  value={step.estimated_time || ''}
                  onChange={(e) => handleStepChange(index, 'estimated_time', e.target.value)}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Box>
            ))}
          </Box>

          {/* Процедури */}
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              {t('content.lessonForm.procedures.title')}
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddProcedure}
                variant="outlined"
                size="small"
                sx={{ ml: 2 }}
              >
                {t('content.lessonForm.procedures.addProcedure')}
              </Button>
            </Typography>
            
            {procedures.map((procedure, index) => (
              <Box key={index} sx={{ p: 2, border: '1px solid #ddd', borderRadius: 1, mb: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="subtitle1">{t('content.lessonForm.procedures.procedure')} {index + 1}</Typography>
                  <IconButton onClick={() => handleRemoveProcedure(index)} color="error" size="small">
                    <DeleteIcon />
                  </IconButton>
                </Box>
                
                <TextField
                  label={t('content.lessonForm.procedures.procedureTitle')}
                  value={procedure.title}
                  onChange={(e) => handleProcedureChange(index, 'title', e.target.value)}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                />
                <TextField
                  label={t('content.lessonForm.procedures.description')}
                  value={procedure.description}
                  onChange={(e) => handleProcedureChange(index, 'description', e.target.value)}
                  multiline
                  rows={3}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                />
                <TextField
                  label={t('content.lessonForm.procedures.conditions')}
                  value={procedure.conditions}
                  onChange={(e) => handleProcedureChange(index, 'conditions', e.target.value)}
                  multiline
                  rows={2}
                  fullWidth
                  required
                />
              </Box>
            ))}
          </Box>

          {/* Тест секция */}
          <Box sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <Typography variant="h6">
                {t('content.lessonForm.test.sectionTitle')}
              </Typography>
              <FormControlLabel
                control={<Switch checked={hasTest} onChange={(e) => setHasTest(e.target.checked)} />}
                label={t('content.lessonForm.test.addTest')}
              />
            </Box>

            {hasTest && (
              <Paper sx={{ p: 2, mb: 2 }}>
                <TestEditor
                  value={testData}
                  onChange={setTestData}
                />
              </Paper>
            )}
          </Box>

          <FormControlLabel
            control={
              <Switch
                checked={lessonData.is_published}
                onChange={(e) => setLessonData({ ...lessonData, is_published: e.target.checked })}
              />
            }
            label={t('content.lessonForm.publish.label')}
          />

          {/* Бутони за действие */}
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 3 }}>
            <Button 
              variant="outlined" 
              onClick={() => navigate('/content')}
            >
              {t('content.lessonForm.buttons.cancel')}
            </Button>
            <Button 
              variant="contained"
              type="submit"
            >
              {t('content.lessonForm.buttons.saveChanges')}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default LessonEdit;
export {}; 