import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Alert,
    CircularProgress
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import axios from 'axios';
import { API_BASE_URL } from '../../config/api.config';
import { useTranslation } from 'react-i18next';

interface Level {
    id: number;
    level: number;
    required_experience: number;
    created_at: string;
}

const LevelManagement: React.FC = () => {
    const { t } = useTranslation();
    const [levels, setLevels] = useState<Level[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [requiredExperience, setRequiredExperience] = useState('');

    useEffect(() => {
        loadLevels();
    }, []);

    const loadLevels = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/levels`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setLevels(response.data);
        } catch (err) {
            setError(t('levels.errors.loadFailed'));
        } finally {
            setLoading(false);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
        setRequiredExperience('');
        setError('');
    };

    const handleClose = () => {
        setOpen(false);
        setError('');
    };

    const handleSubmit = async () => {
        try {
            await axios.post(
                `${API_BASE_URL}/levels`,
                { required_experience: parseInt(requiredExperience) },
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            await loadLevels();
            handleClose();
        } catch (err: any) {
            setError(err.response?.data?.message || t('levels.errors.saveFailed'));
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography variant="h4">{t('levels.title')}</Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleClickOpen}
                >
                    {t('levels.addLevel')}
                </Button>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('levels.table.level')}</TableCell>
                            <TableCell>{t('levels.table.requiredExperience')}</TableCell>
                            <TableCell>{t('levels.table.createdAt')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {levels.map((level) => (
                            <TableRow key={level.id}>
                                <TableCell>{level.level}</TableCell>
                                <TableCell>{level.required_experience} XP</TableCell>
                                <TableCell>
                                    {new Date(level.created_at).toLocaleDateString()}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {t('levels.addLevel')}
                </DialogTitle>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <Box sx={{ pt: 2 }}>
                        <TextField
                            label={t('levels.form.requiredExperience')}
                            type="number"
                            value={requiredExperience}
                            onChange={(e) => setRequiredExperience(e.target.value)}
                            fullWidth
                            autoFocus
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('levels.form.cancel')}</Button>
                    <Button onClick={handleSubmit} variant="contained">
                        {t('levels.form.add')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default LevelManagement; 