import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Chip,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Alert,
  CardMedia,
  Container,
  Divider,
  useTheme,
  Paper
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { lessonService } from '../services/lesson.service';
import { categoryService } from '../services/category.service';
import {
  School as SchoolIcon,
  Timer as TimerIcon,
  FilterList as FilterIcon,
  Search as SearchIcon,
  PlayArrow as StartIcon,
  Quiz as QuizIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Lesson {
  id: number;
  title: string;
  description: string;
  category_name: string;
  difficulty_level: string;
  author_name: string;
  steps_count: number;
  procedures_count: number;
  estimated_time?: number;
  has_test: boolean;
  test_experience_points?: number;
}

const LessonLibrary: React.FC = () => {
  const navigate = useNavigate();
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [filters, setFilters] = useState({
    category: '',
    difficulty: '',
    search: ''
  });
  const { t } = useTranslation();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      setError('');
      console.log('Starting to load lessons...');

      const [lessonsData, categoriesData] = await Promise.all([
        lessonService.getPublicLessons(),
        categoryService.getAll()
      ]);
      
      console.log('Loaded lessons:', lessonsData);
      console.log('Loaded categories:', categoriesData);

      if (Array.isArray(lessonsData)) {
        setLessons(lessonsData);
      } else {
        console.error('Lessons data is not an array:', lessonsData);
        setError('Invalid lessons data format');
      }
      
      if (Array.isArray(categoriesData)) {
        setCategories(categoriesData);
      } else {
        console.error('Categories data is not an array:', categoriesData);
        setError('Invalid categories data format');
      }
    } catch (err) {
      console.error('Error in loadData:', err);
      setError(err instanceof Error ? err.message : 'Failed to load lessons');
    } finally {
      setLoading(false);
    }
  };

  const filteredLessons = lessons.filter(lesson => {
    return (
      (filters.category ? lesson.category_name === filters.category : true) &&
      (filters.difficulty ? lesson.difficulty_level === filters.difficulty : true) &&
      (filters.search
        ? lesson.title.toLowerCase().includes(filters.search.toLowerCase()) ||
          lesson.description.toLowerCase().includes(filters.search.toLowerCase())
        : true)
    );
  });

  const getDifficultyColor = (level: string) => {
    switch (level) {
      case 'beginner':
        return 'success';
      case 'intermediate':
        return 'warning';
      case 'advanced':
        return 'error';
      default:
        return 'default';
    }
  };

  const theme = useTheme();

  const formatCategoryName = (category: any) => {
    if (category.parent_id) {
      const parent = categories.find(c => c.id === category.parent_id);
      if (parent) {
        return `${parent.name} › ${category.name}`;
      }
    }
    return category.name;
  };

  const sortedCategories = [...categories].sort((a, b) => {
    if (a.parent_id === null && b.parent_id !== null) return -1;
    if (a.parent_id !== null && b.parent_id === null) return 1;
    return a.name.localeCompare(b.name);
  });

  const organizeCategories = (categories: any[]) => {
    const categoryMap = new Map();
    const rootCategories: any[] = [];

    categories.forEach(category => {
      categoryMap.set(category.id, {
        ...category,
        children: []
      });
    });

    categories.forEach(category => {
      if (category.parent_id) {
        const parent = categoryMap.get(category.parent_id);
        if (parent) {
          parent.children.push(categoryMap.get(category.id));
        }
      } else {
        rootCategories.push(categoryMap.get(category.id));
      }
    });

    return rootCategories;
  };

  const renderCategoryOptions = (categories: any[], level: number = 0) => {
    return categories.map(category => (
      <React.Fragment key={category.id}>
        <MenuItem 
          value={category.name}
          sx={{
            pl: 2 + (level * 2),
            fontWeight: level === 0 ? 'bold' : 'normal',
            color: level === 0 ? 'primary.main' : 'text.primary'
          }}
        >
          {level > 0 && '└ '}{category.name}
        </MenuItem>
        {category.children && category.children.length > 0 && 
          renderCategoryOptions(category.children, level + 1)
        }
      </React.Fragment>
    ));
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ mb: 6 }}>
        <Typography variant="h3" gutterBottom color="primary">
          {t('library.title')}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {t('library.subtitle')}
        </Typography>
      </Box>

      {/* Filters Section */}
      <Paper sx={{ p: 3, mb: 4, borderRadius: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <FilterIcon sx={{ mr: 1 }} />
          <Typography variant="h6">{t('library.filters.title')}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={t('library.filters.search')}
              variant="outlined"
              value={filters.search}
              onChange={(e) => setFilters({ ...filters, search: e.target.value })}
              InputProps={{
                startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>{t('library.filters.category.label')}</InputLabel>
              <Select
                value={filters.category}
                label={t('library.filters.category.label')}
                onChange={(e) => setFilters({ ...filters, category: e.target.value })}
              >
                <MenuItem value="">{t('library.filters.category.all')}</MenuItem>
                {renderCategoryOptions(organizeCategories(categories))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>{t('library.filters.difficulty.label')}</InputLabel>
              <Select
                value={filters.difficulty}
                label={t('library.filters.difficulty.label')}
                onChange={(e) => setFilters({ ...filters, difficulty: e.target.value })}
              >
                <MenuItem value="">{t('library.filters.difficulty.all')}</MenuItem>
                <MenuItem value="beginner">{t('library.filters.difficulty.beginner')}</MenuItem>
                <MenuItem value="intermediate">{t('library.filters.difficulty.intermediate')}</MenuItem>
                <MenuItem value="advanced">{t('library.filters.difficulty.advanced')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      {/* Results Section */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 4 }}>{error}</Alert>
      ) : (
        <>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" color="text.secondary">
              {filteredLessons.length === 1 
                ? t('library.results.foundOne')
                : t('library.results.foundMany', { count: filteredLessons.length })}
            </Typography>
          </Box>

          <Grid container spacing={3}>
            {filteredLessons.map((lesson) => (
              <Grid item xs={12} sm={6} md={4} key={lesson.id}>
                <Card sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: theme.shadows[8]
                  }
                }}>
                  <CardMedia
                    sx={{
                      height: 140,
                      bgcolor: theme.palette.primary.main,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <SchoolIcon sx={{ fontSize: 60, color: 'white' }} />
                  </CardMedia>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      {lesson.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                      {lesson.description}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                      <Chip
                        label={lesson.category_name}
                        size="small"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label={t(`library.difficulty.${lesson.difficulty_level}`)}
                        size="small"
                        color={getDifficultyColor(lesson.difficulty_level)}
                      />
                      {lesson.estimated_time && (
                        <Chip
                          icon={<TimerIcon />}
                          label={t('library.lesson.estimatedTime', { time: lesson.estimated_time })}
                          size="small"
                          variant="outlined"
                        />
                      )}
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="caption" color="text.secondary">
                        {t('library.lesson.author', { name: lesson.author_name })}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {t('library.lesson.steps', { count: lesson.steps_count })}
                      </Typography>
                    </Box>
                    {lesson.has_test && (
                      <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Chip
                          icon={<QuizIcon />}
                          label={t('library.lesson.test.available', { points: lesson.test_experience_points })}
                          color="secondary"
                          variant="outlined"
                          size="small"
                        />
                      </Box>
                    )}
                  </CardContent>
                  <CardActions sx={{ p: 2, pt: 0 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<StartIcon />}
                      onClick={() => navigate(`/lesson/${lesson.id}`)}
                    >
                      {t('library.lesson.startLearning')}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>

          {filteredLessons.length === 0 && (
            <Box sx={{ 
              textAlign: 'center', 
              py: 8,
              bgcolor: 'background.paper',
              borderRadius: 2
            }}>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                {t('library.results.noResults.title')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t('library.results.noResults.subtitle')}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default LessonLibrary; 